// IrrigationDashboard.js
import React, { useState, useEffect } from 'react';
import './IrrigationDashboard.css';

const IrrigationDashboard = () => {
    const [waterTank, setWaterTank] = useState({ level: 0, temperature: 0, uvLamp: { status: false, remainingTime: 0 } });
    const [fertilizerTank, setFertilizerTank] = useState({ level: 0, ec: 0, pH: 0, temperature: 0, pumps: { acid: false, base: false } });
    const [controllers, setControllers] = useState([]);
    const [roomSensors, setRoomSensors] = useState({ temperature: 0, humidity: 0, co2: 0 });
    const [systemStatus, setSystemStatus] = useState('green');

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch data from the API (replace with actual API calls)
                const waterTankData = await fetch('/api/water-tank').then(res => res.json());
                const fertilizerTankData = await fetch('/api/fertilizer-tank').then(res => res.json());
                const controllersData = await fetch('/api/controllers').then(res => res.json());
                const roomSensorsData = await fetch('/api/room-sensors').then(res => res.json());
                const systemStatusData = await fetch('/api/system-status').then(res => res.json());

                setWaterTank(waterTankData);
                setFertilizerTank(fertilizerTankData);
                setControllers(controllersData);
                setRoomSensors(roomSensorsData);
                setSystemStatus(systemStatusData);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };

        fetchData();
        const interval = setInterval(fetchData, 10000); // Update every 10 seconds
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="dashboard">
            {/* Top Panel */}
            <header className="dashboard-header">
                <div className="logo">Irrigation System</div>
                <div className="date-time">{new Date().toLocaleString()}</div>
                <button className="settings-button">Settings</button>
            </header>

            {/* Water Tank Section */}
            <section className="water-tank-section">
                <h2>Water Tank</h2>
                <div className={`tank-level ${getTankColor(waterTank.level)}`}>
                    {waterTank.level}%
                </div>
                <div>Temperature: {waterTank.temperature}°C</div>
                <div className="uv-lamp-status">
                    UV Lamp: <span className={waterTank.uvLamp.status ? 'on' : 'off'}>{waterTank.uvLamp.status ? 'On' : 'Off'}</span>
                </div>
            </section>

            {/* Fertilizer Tank Section */}
            <section className="fertilizer-tank-section">
                <h2>Fertilizer Tank</h2>
                <div className={`tank-level ${getTankColor(fertilizerTank.level)}`}>
                    {fertilizerTank.level}%
                </div>
                <div>EC: {fertilizerTank.ec} mS/cm</div>
                <div>pH: {fertilizerTank.pH}</div>
                <div>Temperature: {fertilizerTank.temperature}°C</div>
                <div>Pumps:</div>
                <div className="pumps">
                    <div className={`pump acid ${fertilizerTank.pumps.acid ? 'on' : 'off'}`}>Acid</div>
                    <div className={`pump base ${fertilizerTank.pumps.base ? 'on' : 'off'}`}>Base</div>
                </div>
            </section>

            {/* Controllers Section */}
            <section className="controllers-section">
                <h2>Controllers</h2>
                <ul>
                    {controllers.map(controller => (
                        <li key={controller.id} className={controller.status}>
                            {controller.name} - {controller.status}
                        </li>
                    ))}
                </ul>
            </section>

            {/* Room Sensors Section */}
            <section className="room-sensors-section">
                <h2>Room Sensors</h2>
                <div>Temperature: {roomSensors.temperature}°C</div>
                <div>Humidity: {roomSensors.humidity}%</div>
                <div>CO₂: {roomSensors.co2} ppm</div>
            </section>

            {/* System Status Section */}
            <section className="system-status-section">
                <h2>System Status</h2>
                <div className={`status-bar ${systemStatus}`}></div>
                <button>View Errors</button>
            </section>

            {/* Bottom Panel */}
            <footer className="dashboard-footer">
                <button>Logs</button>
                <button>Settings</button>
                <button>Manual Control</button>
            </footer>
        </div>
    );
};

const getTankColor = (level) => {
    if (level < 30) return 'red';
    if (level < 70) return 'yellow';
    return 'green';
};

export default IrrigationDashboard;
