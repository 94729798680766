import axios from 'axios';

// Установка базового URL для всех запросов
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// Настройка интерсепторов запросов
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken"); // Получаем токен из localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Добавляем токен в заголовок Authorization
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Настройка интерсепторов ответов
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401 || error.response?.status === 403) {
      console.error("Unauthorized or forbidden access - possibly invalid token");

      // Обработка попытки обновления токена
      const refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken) {
        try {
          const { data } = await axios.post('/auth/refresh', { token: refreshToken });
          localStorage.setItem("authToken", data.accessToken);
          error.config.headers.Authorization = `Bearer ${data.accessToken}`;
          return axios(error.config); // Повторяем исходный запрос
        } catch (refreshError) {
          console.error("Failed to refresh token:", refreshError);
        }
      }

      // Если рефреш токен не работает, удаляем токен и редиректим
      localStorage.removeItem("authToken");
      window.location.href = "/login"; // Укажите путь при необходимости
    }
    return Promise.reject(error);
  }
);

export default axios;
