import React from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NavbarComponent = ({ isAuthenticated, onLogout }) => {
  return (
    <Navbar bg="success" variant="dark" expand="lg">
      <Navbar.Brand as={Link} to="/">ShefaGreen</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link as={Link} to="/">Home</Nav.Link>
          <Nav.Link as={Link} to="/sensor-data">Sensor Data</Nav.Link>
          <Nav.Link as={Link} to="/admin">Admin</Nav.Link>
          <Nav.Link as={Link} to="/irrigation">Irrigation</Nav.Link>
        </Nav>
        <Nav className="ml-auto">
          {isAuthenticated ? (
            <Button variant="outline-light" onClick={onLogout}>Logout</Button>
          ) : (
            <Button variant="outline-light" as={Link} to="/login">Login</Button>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;